/** @jsx jsx */
import { jsx } from "theme-ui"
import { useColorMode } from "theme-ui"
import logoDefault from "../images/logo/logo.svg"
import logoInverted from "../images/logo/logo-inverted.svg"

const Logo = (props) => {
  const [mode] = useColorMode()
  return (
    <img
      sx={{ display: "inline-block" }}
      src={mode !== "light" ? logoInverted : logoDefault}
      alt={props.alt || "Hölderlinturm Tübingen"}
      width="196"
      height="100"
    />
  )
}

export default Logo
