/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Backdrop from "../components/backdrop"
import Box from "../components/box"
import Container from "../components/container"
import Footer from "../components/footer"
import Header from "../components/header"
import Preflight from "../gatsby-plugin-theme-ui/preflight"
import SkipContent from "../components/skip-content"

const Layout = ({ backdrop, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Preflight />
      <SkipContent />
      <Box minHeight="screen">
        <Container position="relative">
          <Box position="absolute" top={0} left={0} right={0}>
            <Backdrop src={backdrop} />
          </Box>
        </Container>
        <Header siteTitle={data.site.siteMetadata.title} />
        <Container maxWidth="5xl">
          <Box px={6} as="main" id="inhalt">
            <Box position="relative" zIndex="10" pb={12}>
              <Box pt={[12, 16]} pb={[12, 24]}>
                {children}
              </Box>
              <Footer />
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  )
}

Layout.propTypes = {
  title: PropTypes.string,
  backdrop: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Layout
