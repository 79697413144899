import React, { useState } from "react" // eslint-disable-line no-unused-vars

/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import Container from "./container"
import Box from "./box"
import Button from "./button"
import Stack from "./stack"
import Inline from "./inline"
import ThemeToggle from "./theme-toggle"
import Logo from "./logo"
import Text from "./text"
import Modal from "./modal"

const Header = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false)

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  const navItemStyles = {
    display: "inline-flex",
    color: "text",
    py: 2,
    borderTopWidth: 4,
    borderBottomWidth: 4,
    mb: "calc((0.5rem + 4px)*-1)",
    borderStyle: "solid",
    borderColor: "transparent",
    lineHeight: 1,
    "&.active": {
      borderBottomColor: "primary",
    },
  }

  const langItemStyles = {
    color: "text",
    pt: "2px",
    borderBottomWidth: 2,
    borderStyle: "solid",
    borderColor: "transparent",
    "&.active": {
      color: "text",
      borderColor: "primary",
    },
  }

  const menuItemStyle = {
    color: "text",
    "&.active": {
      color: "primary",
    },
  }

  const navigation = [
    {
      title: "Ausstellungen",
      link: "/ausstellungen",
    },
    {
      title: "Veranstaltungen",
      link: "/veranstaltungen",
    },
    {
      title: "Digital",
      link: "/digital",
    },
    {
      title: "Für Schulen",
      link: "/fuer-schulen",
    },
    {
      title: "Führungen",
      link: "/fuehrungen",
    },
  ]

  const languages = [
    {
      title: "Leichte Sprache",
      link: "/leichte-sprache",
    },
    {
      title: "Gebärdensprache",
      link: "/gebaerdensprache",
    },
    {
      title: "English",
      link: "/english",
    },
    {
      title: "Français",
      link: "/francais",
    },
  ]

  const renderNavItem = (item, index) => {
    return item ? (
      <Box pl={[3, 6]} key={`nav_item_${index}`}>
        <Link
          to={item.link}
          activeClassName="active"
          partiallyActive={true}
          sx={navItemStyles}
        >
          <Text as="span" size={[2, 2, 3, 4, "28px"]} sans bold color="inherit">
            {item.title}
          </Text>
        </Link>
      </Box>
    ) : null
  }

  const renderLangItem = (item, index) => {
    return item ? (
      <Link
        to={item.link}
        activeClassName="active"
        partiallyActive={true}
        key={`nav_item_${index}`}
        sx={langItemStyles}
      >
        <Text as="span" size={2} sans color="inherit">
          {item.title}
        </Text>
      </Link>
    ) : null
  }

  const renderMenuItem = (item, index) => {
    return item ? (
      <Box px={3} key={`menu_item_${index}`}>
        <Link
          to={item.link}
          activeClassName="active"
          partiallyActive={true}
          onClick={closeModal}
          sx={menuItemStyle}
        >
          <Text
            as="span"
            align="center"
            size={[3, 4]}
            sans
            bold
            color="inherit"
          >
            {item.title}
          </Text>
        </Link>
      </Box>
    ) : null
  }

  const renderLangMenuItem = (item, index) => {
    return item ? (
      <Box px={3} key={`menu_item_${index}`}>
        <Link
          to={item.link}
          activeClassName="active"
          partiallyActive={true}
          onClick={closeModal}
          sx={menuItemStyle}
        >
          <Text as="span" align="center" size={[3]} sans color="inherit">
            {item.title}
          </Text>
        </Link>
      </Box>
    ) : null
  }

  return (
    <Box>
      <Box px={[6, null, null, null, 12]} py={6}>
        <Container position="relative">
          <Box
            as="header"
            display="flex"
            alignX="space-between"
            alignY="stretch"
          >
            <Box id="logo" maxWidth={["32", "32", "40", "40", "48"]}>
              <Link
                to="/"
                activeClassName="active"
                sx={{
                  display: "inline-block",
                  position: "relative",
                  ":not(.active):hover .home-link, :not(.active):focus .home-link":
                    {
                      display: "block",
                    },
                  "&.active": {
                    cursor: "default",
                  },
                }}
              >
                <Logo />
                <Text
                  as="span"
                  sans
                  size={1}
                  textAlign="center"
                  position="absolute"
                  left={0}
                  right={0}
                  mt={2}
                  top="100%"
                  className="home-link"
                  sx={{ display: "none", textDecoration: "underline" }}
                >
                  zur Startseite
                </Text>
              </Link>
            </Box>
            <Box
              id="navigation"
              pl={[12, 6]}
              display="flex"
              flexDirection="column"
              sx={{
                justifyContent: ["center", "center", "center", "space-between"],
                alignItems: "right",
              }}
            >
              <Box display="flex" alignY="center" alignX="right">
                <Box display={["flex", "flex", "flex", "none"]} alignX="right">
                  <Button onClick={openModal}>Menü</Button>
                </Box>
                <Box display={["none", "none", "none", "block"]} alignX="right">
                  <Inline space={6} alignY="center" alignX="right">
                    <Inline as="ul" space={[null, null, 6]} alignX="right">
                      {languages.map((item) => renderLangItem(item))}
                    </Inline>
                    <ThemeToggle />
                  </Inline>
                </Box>
              </Box>
              <Box
                as="nav"
                role="navigation"
                aria-label="Hauptnavigation"
                display={["none", "none", "none", "block"]}
              >
                <Inline as="ul" space={0} alignX="right">
                  {navigation.map((item) => renderNavItem(item))}
                </Inline>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Modal
        isOpen={modalIsOpen}
        onClose={closeModal}
        contentLabel="Hauptnavigation"
      >
        <Box height="100%" display="flex" alignX="center" alignY="center">
          <Stack>
            <Box as="nav">
              <Stack as="ul" space={3} dividers>
                {navigation.map((item) => renderMenuItem(item))}
              </Stack>
            </Box>
            <Box as="nav">
              <Stack as="ul" space={3}>
                {languages.map((item) => renderLangMenuItem(item))}
              </Stack>
            </Box>
            <Box
              display="flex"
              alignX="center"
              px={3}
              key={`menu_item_theme_toggle`}
            >
              <ThemeToggle />
            </Box>
          </Stack>
        </Box>
      </Modal>
    </Box>
  )
}

export default Header
