import React, { useState } from "react" // eslint-disable-line no-unused-vars

import Box from "./box"
import Column from "./column"
import Columns from "./columns"
import Divider from "./divider"
import Grid from "./grid"
import Heading from "./heading"
import Inline from "./inline"
import OpeningTimes from "./opening-times"
import Stack from "./stack"
import Text from "./text"
import TextLink from "./textlink"

const Footer = () => {
  const [explanationIsOpen, setIsOpen] = React.useState(false)

  function openExplanation() {
    setIsOpen(true)
  }

  return (
    <Box as="footer">
      <Stack space={3}>
        <Box display="flex" alignX="right">
          <Stack space={3}>
            <Box
              as={explanationIsOpen ? "span" : "button"}
              onClick={openExplanation}
              bg="transparent"
              aria-label="Nächstens mehr. Was steckt dahinter?"
              sx={{
                cursor: explanationIsOpen ? "default" : "pointer",
                display: "block",
                width: "full",
                textAlign: "left",
                position: "relative",
                zIndex: "10",
              }}
            >
              <Heading as="div" align="right" level={2} lineHeight="1">
                <Text as="span" color="primary">
                  »
                </Text>
                Nächstens mehr.
                <Text as="span" color="primary">
                  «
                </Text>
              </Heading>
            </Box>
            {explanationIsOpen ? (
              <Box bg="muted" p={6} maxWidth={92}>
                <Stack space={1} alignX="left">
                  <Heading>»So dacht‘ ich. Nächstens mehr.«</Heading>
                  <Text>
                    Mit diesen Worten endet der zweite Band von Hölderlins
                    Briefroman ›Hyperion‹. Eine Fortsetzung gab es nie.
                  </Text>
                </Stack>
              </Box>
            ) : null}
          </Stack>
        </Box>
        <Box bg="background" position="relative">
          <Stack space={12}>
            <Divider size={4} />
            <Stack space={24}>
              <Columns
                collapse={[true, false]}
                space={12}
                as="nav"
                aria-label="Fußzeilen-Navigation"
              >
                <Column>
                  <Grid columns={[1, 1, 2, 3]} space={[12, 12, 12, 6]}>
                    <Stack space={6}>
                      <Heading as="strong" level={5}>
                        Besuch
                      </Heading>
                      <Stack as="ul" space={2}>
                        <TextLink to="/anreise">Anreise</TextLink>
                        <TextLink to="/ausstellungen">Ausstellungen</TextLink>
                        <TextLink to="/veranstaltungen">
                          Veranstaltungen
                        </TextLink>
                        <TextLink to="/fuer-schulen">Für Schulen</TextLink>
                        <TextLink to="/fuehrungen">Führungen</TextLink>
                      </Stack>
                    </Stack>
                    <Stack space={6}>
                      <Heading as="strong" level={5}>
                        Digital
                      </Heading>
                      <Stack as="ul" space={2}>
                        <TextLink to="/dauerausstellung">
                          Dauerausstellung
                        </TextLink>
                        <TextLink to="/sonderausstellungen">
                          Sonderausstellungen
                        </TextLink>
                        <TextLink to="/aus-dem-archiv-geholt">
                          Aus dem Archiv geholt
                        </TextLink>
                        <TextLink to="/turmgespraeche">Turmgespräche</TextLink>
                      </Stack>
                    </Stack>
                    <Stack space={6}>
                      <Heading as="strong" level={5}>
                        Museum
                      </Heading>
                      <Stack as="ul" space={2}>
                        <TextLink to="/newsletter">Newsletter</TextLink>
                        <TextLink to="/kontakt">Kontakt</TextLink>
                        <TextLink to="/presse">Presse</TextLink>
                        <TextLink to="/mitarbeiten">
                          Praktikum & Projektmitarbeit
                        </TextLink>
                      </Stack>
                    </Stack>
                  </Grid>
                </Column>

                <Column width="content">
                  <Stack space={6}>
                    <Heading as="strong" level={5}>
                      Öffnungszeiten
                    </Heading>
                    <OpeningTimes />
                  </Stack>
                </Column>
              </Columns>

              <Stack>
                <Columns collapse={[true, false]}>
                  <Column width={["full", "1/2", "1/3"]}>
                    <Stack>
                      <Stack space={1}>
                        <Heading as="strong">Hölderlinturm Tübingen</Heading>
                        <Text>
                          Bursagasse 6 <br />
                          72070 Tübingen
                        </Text>
                      </Stack>
                      <TextLink href="mailto:hoelderlinturm@tuebingen.de">
                        hoelderlinturm@tuebingen.de
                      </TextLink>
                    </Stack>
                  </Column>
                  <Column width={["full", "1/2", "2/3"]}>
                    <Text as="div" align={["left", "right"]}>
                      <Stack space={3}>
                        <Inline space={3} alignX={[null, "right"]}>
                          <TextLink to="/datenschutz">Datenschutz</TextLink>
                          <TextLink to="/impressum">Impressum</TextLink>
                        </Inline>
                      </Stack>
                    </Text>
                  </Column>
                </Columns>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Box>
  )
}

export default Footer
