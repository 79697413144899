import React from "react"
import PropTypes from "prop-types"
import OpeningTimesItem from "./opening-times-item"
import Stack from "./stack"
import Text from "./text"

import { format } from "date-fns"
import { de } from "date-fns/locale"

const OpeningTimes = ({ message, columnWidth, space, size }) => {
  const date = Date.now()
  const today = format(date, "EEEE", { locale: de })

  return message ? (
    <Text sans>{message}</Text>
  ) : (
    <Stack as="ol" space={space}>
      <OpeningTimesItem
        isToday={today === "Montag"}
        size={size}
        columnWidth={columnWidth}
        day="Montag"
        time="11:00 - 17:00"
      />
      <OpeningTimesItem
        isToday={today === "Dienstag"}
        size={size}
        columnWidth={columnWidth}
        day="Dienstag"
        time="geschlossen"
      />
      <OpeningTimesItem
        isToday={today === "Mittwoch"}
        size={size}
        columnWidth={columnWidth}
        day="Mittwoch"
        time="geschlossen"
      />
      <OpeningTimesItem
        isToday={today === "Donnerstag"}
        size={size}
        columnWidth={columnWidth}
        day="Donnerstag"
        time="11:00 - 17:00"
      />
      <OpeningTimesItem
        isToday={today === "Freitag"}
        size={size}
        columnWidth={columnWidth}
        day="Freitag"
        time="11:00 - 17:00"
      />
      <OpeningTimesItem
        isToday={today === "Samstag"}
        size={size}
        columnWidth={columnWidth}
        day="Samstag"
        time="11:00 - 17:00"
      />
      <OpeningTimesItem
        isToday={today === "Sonntag"}
        size={size}
        columnWidth={columnWidth}
        day="Sonntag"
        time="11:00 - 17:00"
      />
    </Stack>
  )
}

OpeningTimes.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  space: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  columnWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

OpeningTimes.defaultProps = {
  space: 2,
}

export default OpeningTimes
